import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, IconButton } from "@mui/material";
import {
  Facebook,
  Instagram,
  YouTube,
  WhatsApp,
  ArrowUpward,
} from "@mui/icons-material";
import { shades } from "../../theme";

function Footer({ onCookieSettingsClick }) {
  const currentYear = new Date().getFullYear();
  const [isVisible, setIsVisible] = useState(false);
  const theme = useTheme();

  const contactNumber = "0418700511";
  const address = "Dorpsstraat 20, 5314 AD Bruchem";
  const mapsLink = "https://maps.app.goo.gl/u7RJnsurZ9f4Q4QB6";

  const openingHours = [
    "28 december: 09:00 - 21:00",
    "30 december: 09:00 - 21:00",
    "31 december: 09:00 - 17:00",
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  return (
    <Box
      marginTop="50px"
      padding="30px 0"
      backgroundColor={theme.palette.background.paper}
      color={theme.palette.text.primary}
      sx={{
        "& ul": { paddingInlineStart: "0", margin: "0" },
        "& li": { listStyle: "none", marginBottom: "10px" },
        "& a": { textDecoration: "none", color: "inherit" },
        "@media (max-width: 768px)": {
          padding: "20px 10px",
          "& .footer-column": {
            marginBottom: "20px",
          },
          "& .desktop-text": {
            display: "none",
          },
        },
      }}
    >
      <Box
        width="100%"
        margin="auto"
        maxWidth="80%"
        display="flex"
        justifyContent="space-between"
        flexDirection={{ xs: "column", md: "row" }}
        rowGap="20px"
        columnGap="clamp(20px, 30px, 40px)"
      >
        <Box className="footer-column" flexBasis="25%">
          <Typography
            variant="h3"
            fontWeight="bold"
            mb="10px"
            color={shades.secondary[500]}
          >
            VUURWERK BRUCHEM
          </Typography>
          <Typography
            variant="body1"
            className="desktop-text"
            sx={{
              display: { xs: "none", md: "block" },
            }}
          >
            Het beste vuurwerk in de Bommelerwaard voor een spectaculaire
            jaarwisseling! Laat de nacht oplichten met onze adembenemende
            collectie, zorgvuldig samengesteld om je te betoveren.
          </Typography>
          <Box
            mt="10px"
            sx={{
              display: { xs: "none", md: "block" }, // Alleen zichtbaar op desktop
            }}
          >
            <a
              href="https://vuurwerkcheck.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/logo-vuurwerkcheck.webp"
                alt="Vuurwerk Check Logo"
                style={{
                  maxWidth: "150px", // Kleinere breedte voor het logo
                  height: "auto",
                }}
              />
            </a>
          </Box>

          <Typography
            variant="body1"
            className="mobile-text"
            sx={{
              display: { xs: "block", md: "none" }, // Alleen zichtbaar op mobiel
            }}
          >
            Het beste vuurwerk in de Bommelerwaard, laagste prijs, parkeren voor
            de deur, ruim assortiment.
          </Typography>

          <Box
            mt="10px"
            sx={{
              display: { xs: "block", md: "none" }, // Alleen zichtbaar op mobiel
            }}
          >
            <a
              href="https://vuurwerkcheck.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/logo-vuurwerkcheck.webp"
                alt="Vuurwerk Check Logo"
                style={{
                  maxWidth: "150px", // Kleinere breedte voor mobiel
                  height: "auto",
                }}
              />
            </a>
          </Box>
        </Box>

        <Box className="footer-column" flexBasis="25%">
          <Typography variant="h3" mb="10px">
            Openingstijden
          </Typography>
          <ul>
            {openingHours.map((time, index) => (
              <li key={index}>{time}</li>
            ))}
          </ul>
        </Box>

        <Box className="footer-column" flexBasis="25%">
          <Typography variant="h3" mb="10px">
            Volg ons!
          </Typography>
          <Box className="socials-container">
            <a
              data-social="Instagram"
              style={{
                "--accent-color": "#fe107c",
                backgroundColor: "white",
                color: "#fe107c",
              }}
              href="https://www.instagram.com/vuurwerkbruchem/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram />
            </a>
            <a
              data-social="Facebook"
              style={{
                "--accent-color": "#106bff",
                backgroundColor: "white",
                color: "#106bff",
              }}
              href="https://www.facebook.com/vuurwerkbruchem.nl/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Facebook />
            </a>
            <a
              data-social="YouTube"
              style={{
                "--accent-color": "red",
                backgroundColor: "white",
                color: "red",
              }}
              href="https://www.youtube.com/@vuurwerkbruchem"
              target="_blank"
              rel="noopener noreferrer"
            >
              <YouTube />
            </a>
            <a
              data-social="WhatsApp"
              style={{
                "--accent-color": "#25D366",
                backgroundColor: "white",
                color: "#25D366",
              }}
              href="https://wa.me/31616175368"
              target="_blank"
              rel="noopener noreferrer"
            >
              <WhatsApp />
            </a>
          </Box>
        </Box>

        <Box className="footer-column" flexBasis="25%">
          <Typography variant="h3" mb="10px">
            Contact
          </Typography>
          <Typography variant="body1" mb="10px">
            Telefoon: {contactNumber}
          </Typography>
          <Typography variant="body1" sx={{ wordWrap: "break-word" }} mb="10px">
            Adres:{" "}
            <a href={mapsLink} target="_blank" rel="noopener noreferrer">
              {address}
            </a>
          </Typography>
          <Typography variant="body1" mb="10px">
            <a href="/verkoopvoorwaarden">Verkoopvoorwaarden</a> &{" "}
            <a href="/privacy-policy">Privacyverklaring</a>
          </Typography>
          <Typography variant="body1">
            <a href="#cookiesettings" onClick={onCookieSettingsClick}>
              Cookie-instellingen
            </a>
          </Typography>
        </Box>
      </Box>
      <Box
        width="100%"
        textAlign="center"
        paddingTop="10px"
        position="relative"
      >
        <Typography variant="body2" color="textSecondary">
          © {currentYear} {" Vuurwerk Bruchem & "}
          <a
            href="https://www.martheijnen.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <b>Mart Heijnen</b>
          </a>
          {" - "}
          <a
            href="https://www.fireworkflow.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            FireWorkFlow
          </a>
          {" : het complete vuurwerkbeheersysteem voor vuurwerkverkoop"}
        </Typography>
        {isVisible && (
          <IconButton
            onClick={scrollToTop}
            sx={{
              position: "fixed",
              bottom: "20px",
              right: "20px",
              backgroundColor: shades.secondary[500],
              color: "white",
              "&:hover": {
                backgroundColor: shades.secondary[700],
              },
            }}
            aria-label="Scroll to top"
          >
            <ArrowUpward />
          </IconButton>
        )}
      </Box>
    </Box>
  );
}

export default Footer;
