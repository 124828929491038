import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Divider,
  Slider,
  IconButton,
  Select,
  MenuItem,
  useMediaQuery,
  Tooltip,
  Collapse,
  TextField,
  Pagination,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { FilterList, ExpandLess, ExpandMore } from "@mui/icons-material";
import { useSelector, useDispatch } from "react-redux";
import { setItems } from "../../state";
import Item from "../../components/Item";
import { useLocation, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { BASE_URL } from "../../api";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const ShoppingList = ({ isSearchOpen }) => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.cart.items);
  const breakPoint = useMediaQuery("(min-width:600px)");
  const isMobile = !breakPoint;
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();

  const formatCategoryName = (category) => {
    let spacedString = category.replace(/(?<=[a-z])([A-Z])/g, " $1");
    spacedString = spacedString.replace(
      /(?<=[a-zA-Z])(\d+)|(\d+)(?=[a-zA-Z])/g,
      " $1$2 "
    );
    const splitWords = spacedString.trim().split(/\s+/);
    return splitWords
      .map((word) => {
        if (!isNaN(word)) return word;
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  const params = new URLSearchParams(location.search);
  const categoryFromURL = params.getAll("category");
  const suppliersFromURL = params.getAll("suppliers");
  const kaliberFromURL = params.getAll("kaliber");
  const priceRangeFromURL = params
    .get("priceRange")
    ?.split(",")
    .map(Number) || [0, 500];
  const bestsellersFromURL = params.get("bestsellers") === "true";
  const inStockFromURL = params.get("inStock") === "true";

  const [filters, setFilters] = useState({
    searchTerm: "",
    categories:
      categoryFromURL.length > 0 ? categoryFromURL.map(formatCategoryName) : [],
    suppliers: suppliersFromURL.length > 0 ? suppliersFromURL : [],
    kaliber: kaliberFromURL.length > 0 ? kaliberFromURL : [],
    priceRange: priceRangeFromURL,
    bestsellers: bestsellersFromURL,
    inStock: inStockFromURL,
  });

  const categories = useMemo(
    () =>
      Array.from(
        new Set(
          items.map((item) => formatCategoryName(item.attributes.category))
        )
      ).sort((a, b) => a.localeCompare(b)),
    [items]
  );

  const suppliers = useMemo(
    () =>
      Array.from(
        new Set(items.map((item) => item.attributes.supplier).filter(Boolean))
      ).sort((a, b) => a.localeCompare(b)),
    [items]
  );

  const kaliberOptions = useMemo(() => {
    const kaliberSet = new Set(
      items.map((item) => item.attributes.kaliber).filter(Boolean)
    );
    kaliberSet.add("Niet bekend");
    return Array.from(kaliberSet).sort((a, b) => a.localeCompare(b));
  }, [items]);

  const [loading, setLoading] = useState(true);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [sortOption, setSortOption] = useState(() => {
    return localStorage.getItem("sortOption") || ""; // Load from localStorage
  });

  const [itemsPerPage, setItemsPerPage] = useState(() => {
    return Number(localStorage.getItem("itemsPerPage")) || 10; // Load from localStorage
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [openCategories, setOpenCategories] = useState(false);
  const [openSuppliers, setOpenSuppliers] = useState(false);
  const [openKaliber, setOpenKaliber] = useState(false);

  useEffect(() => {
    async function getItems() {
      const response = await fetch(
        `${BASE_URL}/api/items?populate=image&publicationState=preview`
      );
      const data = await response.json();
      dispatch(setItems(data.data));
      setLoading(false);
    }

    getItems();
  }, [dispatch]);

  const handleCheckboxChange = useCallback(
    (event) => {
      const { name, value } = event.target;

      setFilters((prevFilters) => {
        let updatedArray;

        if (value === "Alles") {
          updatedArray =
            prevFilters[name].length ===
            (name === "categories" ? categories.length : suppliers.length)
              ? []
              : name === "categories"
              ? categories
              : suppliers;
        } else {
          updatedArray = prevFilters[name].includes(value)
            ? prevFilters[name].filter((item) => item !== value)
            : [...prevFilters[name], value];
        }

        return { ...prevFilters, [name]: updatedArray };
      });

      setCurrentPage(1);
    },
    [categories, suppliers]
  );

  const handleSliderChange = useCallback(
    (event, newValue) => {
      setFilters({
        ...filters,
        priceRange: newValue,
      });
      setCurrentPage(1);
    },
    [filters]
  );

  const toggleInStock = useCallback(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      inStock: !prevFilters.inStock,
    }));
    setCurrentPage(1);
  }, []);

  const toggleHighlight = useCallback(() => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      bestsellers: !prevFilters.bestsellers,
    }));
    setCurrentPage(1);
  }, []);

  const handleSearchTermChange = (event) => {
    setFilters({
      ...filters,
      searchTerm: event.target.value,
    });
    setCurrentPage(1);
  };

  useEffect(() => {
    const newParams = new URLSearchParams();

    if (filters.categories.length > 0) {
      newParams.set("category", filters.categories.join(","));
    }
    if (filters.suppliers.length > 0) {
      newParams.set("suppliers", filters.suppliers.join(","));
    }
    if (filters.kaliber.length > 0) {
      newParams.set("kaliber", filters.kaliber.join(","));
    }
    if (filters.priceRange) {
      newParams.set("priceRange", filters.priceRange.join(","));
    }
    if (filters.bestsellers) {
      newParams.set("bestsellers", "true");
    }
    if (filters.inStock) {
      newParams.set("inStock", "true");
    }

    navigate(
      {
        search: newParams.toString().replace(/,/g, "&"),
      },
      { replace: true }
    );
  }, [filters, navigate]);

  const applyFilters = useCallback(() => {
    setLoading(true);
    setLoading(false);
  }, []);

  const debouncedApplyFilters = useMemo(
    () => debounce(applyFilters, 300),
    [applyFilters]
  );

  useEffect(() => {
    debouncedApplyFilters();
    return () => {
      debouncedApplyFilters.cancel();
    };
  }, [filters, sortOption, debouncedApplyFilters]);

  const toggleFilters = useCallback(() => {
    setFiltersVisible(!filtersVisible);
  }, [filtersVisible]);

  const handleSortChange = useCallback((event) => {
    const newSortOption = event.target.value;
    setSortOption(newSortOption);
    localStorage.setItem("sortOption", newSortOption);
    setCurrentPage(1);
  }, []);

  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = event.target.value;
    setItemsPerPage(newItemsPerPage);
    localStorage.setItem("itemsPerPage", newItemsPerPage);
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const applyFiltersAndSort = useMemo(() => {
    const filteredAndSortedItems = items
      .filter((item) => {
        const priceToUse =
          item.attributes.priceDiscount || item.attributes.price;

        return (
          (filters.searchTerm === "" ||
            item.attributes.name
              .toLowerCase()
              .includes(filters.searchTerm.toLowerCase())) &&
          (filters.categories.length === 0 ||
            filters.categories.includes(
              formatCategoryName(item.attributes.category)
            )) &&
          (filters.suppliers.length === 0 ||
            filters.suppliers.includes(item.attributes.supplier)) &&
          (filters.kaliber.length === 0 ||
            filters.kaliber.includes(item.attributes.kaliber) ||
            (filters.kaliber.includes("Niet bekend") &&
              !item.attributes.kaliber)) &&
          priceToUse >= filters.priceRange[0] &&
          priceToUse <= filters.priceRange[1] &&
          (!filters.inStock || !item.attributes.outOfStock) &&
          (!filters.bestsellers || item.attributes.highlight)
        );
      })
      .sort((a, b) => {
        if (!sortOption) return 0;

        const priceA = a.attributes.priceDiscount || a.attributes.price;
        const priceB = b.attributes.priceDiscount || b.attributes.price;

        if (sortOption === "artNumber") {
          return a.attributes.artNumber.localeCompare(b.attributes.artNumber);
        } else if (sortOption === "priceAsc") {
          return priceA - priceB;
        } else if (sortOption === "priceDesc") {
          return priceB - priceA;
        } else if (sortOption === "nameAsc") {
          return a.attributes.name.localeCompare(b.attributes.name);
        } else if (sortOption === "nameDesc") {
          return b.attributes.name.localeCompare(a.attributes.name);
        }
        return 0;
      });

    setTotalItems(filteredAndSortedItems.length);

    return filteredAndSortedItems.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );
  }, [items, filters, sortOption, currentPage, itemsPerPage]);

  if (loading) {
    return (
      <Typography variant="h3" align="center">
        Laden...
      </Typography>
    );
  }

  return (
    <Box
      width="80%"
      margin="80px auto"
      className="shift-on-search"
      display="flex"
      flexDirection="column"
    >
      {/* Filter button for mobile */}
      {isMobile && !filtersVisible && (
        <Box
          sx={{
            position: "fixed",
            bottom: "0",
            left: 0,
            right: 0,
            zIndex: 9,
            backgroundColor: theme.palette.background.default,
            borderTop: `1px solid ${theme.palette.divider}`,
          }}
        >
          <IconButton
            onClick={toggleFilters}
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              color: theme.palette.text.primary, // Zorgt ervoor dat het icoontje wit wordt in dark mode
            }}
          >
            <FilterList />
            <Typography
              variant="button"
              sx={{ ml: 1, color: theme.palette.text.primary }} // Zorgt ervoor dat de tekst wit wordt in dark mode
            >
              Filter
            </Typography>
          </IconButton>
        </Box>
      )}

      {/* Sticky Header for Desktop */}
      {!isMobile && (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
          sx={{
            position: "sticky",
            top: "60px", // Height of the navbar
            zIndex: 10,
            backgroundColor: theme.palette.background.default,
            borderBottom: `1px solid ${theme.palette.divider}`,
            padding: "10px 0",
          }}
        >
          <Typography variant="h3" textAlign="left">
            Maak kennis met ons <b>uitgebreide assortiment!</b>
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="body1" sx={{ mr: 2 }}>
              Aantal items per pagina:
            </Typography>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              sx={{ minWidth: 80, mr: 2 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={75}>75</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <Typography variant="body1" sx={{ mr: 2 }}>
              Sorteren op:
            </Typography>
            <Select
              value={sortOption}
              onChange={handleSortChange}
              sx={{ minWidth: 150 }}
              displayEmpty
            >
              <MenuItem value="" disabled>
                Sorteren op
              </MenuItem>
              <MenuItem value="nameAsc">Naam (A-Z)</MenuItem>
              <MenuItem value="nameDesc">Naam (Z-A)</MenuItem>
              <MenuItem value="artNumber">Artikelnummer</MenuItem>
              <MenuItem value="priceAsc">Prijs (Laag-Hoog)</MenuItem>
              <MenuItem value="priceDesc">Prijs (Hoog-Laag)</MenuItem>
            </Select>
          </Box>
        </Box>
      )}

      <Box
        display="flex"
        width="100%"
        flexDirection={isMobile ? "column" : "row"}
      >
        {/* Filters Panel */}
        {(filtersVisible || !isMobile) && (
          <Box
            position={isMobile ? "fixed" : "sticky"}
            top="60px"
            left={isMobile ? "0" : "auto"}
            width={isMobile ? "100%" : "20%"}
            height={isMobile ? "calc(100vh - 60px)" : "auto"}
            bgcolor={isMobile ? theme.palette.background.default : "inherit"}
            zIndex={isMobile ? "999" : "auto"}
            p={isMobile ? 2 : 2}
            display={isMobile && !filtersVisible ? "none" : "block"}
            boxShadow={isMobile ? 3 : "none"}
            sx={{
              borderRadius: isMobile ? "10px" : "none",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Typography variant="h3" textAlign="left">
                Filteren
              </Typography>
              {isMobile && (
                <IconButton onClick={toggleFilters} color="primary">
                  <CloseIcon />
                </IconButton>
              )}
            </Box>

            <FormGroup sx={{ alignItems: "flex-start" }}>
              <TextField
                label="Zoek op naam"
                variant="outlined"
                fullWidth
                value={filters.searchTerm}
                onChange={handleSearchTermChange}
                sx={{ marginBottom: "16px" }}
              />

              <Box
                onClick={() => setOpenCategories(!openCategories)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle1">Categorieën</Typography>
                {openCategories ? <ExpandLess /> : <ExpandMore />}
              </Box>
              <Collapse in={openCategories}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        filters.categories.length === categories.length &&
                        categories.length > 0
                      }
                      onChange={() =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          categories:
                            filters.categories.length === categories.length
                              ? []
                              : categories,
                        }))
                      }
                      name="categories"
                      value="Alles"
                    />
                  }
                  label="Alles"
                  sx={{ marginBottom: "8px", width: "100%" }}
                />
                {categories.map((category) => (
                  <Tooltip title={category} placement="top" key={category}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filters.categories.includes(category)}
                          onChange={handleCheckboxChange}
                          name="categories"
                          value={category}
                        />
                      }
                      label={category}
                      sx={{
                        marginBottom: "8px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    />
                  </Tooltip>
                ))}
              </Collapse>

              <Divider sx={{ my: 2 }} />

              <Box
                onClick={() => setOpenSuppliers(!openSuppliers)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle1">Leveranciers</Typography>
                {openSuppliers ? <ExpandLess /> : <ExpandMore />}
              </Box>
              <Collapse in={openSuppliers}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        filters.suppliers.length === suppliers.length &&
                        suppliers.length > 0
                      }
                      onChange={() =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          suppliers:
                            filters.suppliers.length === suppliers.length
                              ? []
                              : suppliers,
                        }))
                      }
                      name="suppliers"
                      value="Alles"
                    />
                  }
                  label="Alles"
                  sx={{ marginBottom: "8px", width: "100%" }}
                />
                {suppliers.map((supplier) => (
                  <Tooltip title={supplier} placement="top" key={supplier}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={filters.suppliers.includes(supplier)}
                          onChange={handleCheckboxChange}
                          name="suppliers"
                          value={supplier}
                        />
                      }
                      label={supplier}
                      sx={{
                        marginBottom: "8px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "100%",
                      }}
                    />
                  </Tooltip>
                ))}
              </Collapse>

              <Divider sx={{ my: 2 }} />

              <Box
                onClick={() => setOpenKaliber(!openKaliber)}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  cursor: "pointer",
                  mb: 1,
                }}
              >
                <Typography variant="subtitle1">Kaliber</Typography>
                {openKaliber ? <ExpandLess /> : <ExpandMore />}
              </Box>
              <Collapse in={openKaliber}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        filters.kaliber.length === kaliberOptions.length &&
                        kaliberOptions.length > 0
                      }
                      onChange={() =>
                        setFilters((prevFilters) => ({
                          ...prevFilters,
                          kaliber:
                            filters.kaliber.length === kaliberOptions.length
                              ? []
                              : kaliberOptions,
                        }))
                      }
                      name="kaliber"
                      value="Alles"
                    />
                  }
                  label="Alles"
                  sx={{ marginBottom: "8px", width: "100%" }}
                />
                {kaliberOptions.map((kaliber) => (
                  <FormControlLabel
                    key={kaliber}
                    control={
                      <Checkbox
                        checked={filters.kaliber.includes(kaliber)}
                        onChange={handleCheckboxChange}
                        name="kaliber"
                        value={kaliber}
                      />
                    }
                    label={kaliber === "Niet bekend" ? "Niet bekend" : kaliber}
                    sx={{
                      marginBottom: "8px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "100%",
                    }}
                  />
                ))}
              </Collapse>

              <Divider sx={{ my: 2 }} />

              <Typography gutterBottom>Prijs Range (€)</Typography>
              <Slider
                value={filters.priceRange}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                max={500}
                step={5}
                sx={{ width: isMobile ? "100%" : "90%" }}
              />

              <Divider sx={{ my: 2 }} />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.inStock}
                    onChange={toggleInStock}
                    name="inStock"
                  />
                }
                label="Op voorraad"
                sx={{ marginBottom: "8px", width: "100%" }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={filters.bestsellers}
                    onChange={toggleHighlight}
                    name="bestsellers"
                  />
                }
                label="Bestsellers"
                sx={{ marginBottom: "8px", width: "100%" }}
              />

              {isMobile && filtersVisible && (
                <Box
                  sx={{
                    position: "fixed",
                    bottom: "0",
                    left: 0,
                    right: 0,
                    zIndex: 9,
                    backgroundColor: theme.palette.background.default,
                    borderTop: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  <IconButton
                    onClick={() => {
                      applyFilters();
                      setFiltersVisible(false);
                    }}
                    color="secondary"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <CheckIcon />
                    <Typography variant="button" sx={{ ml: 1 }}>
                      Toepassen
                    </Typography>
                  </IconButton>
                </Box>
              )}
            </FormGroup>
          </Box>
        )}

        {/* Products List */}
        <Box
          width={isMobile ? "100%" : "78%"}
          ml={isMobile ? 0 : 2}
          sx={{
            overflowY: "auto",
            gridTemplateColumns: isMobile
              ? "repeat(2, 1fr)"
              : "repeat(auto-fill, 300px)",
          }}
        >
          <Box
            margin="0 auto"
            display="grid"
            gridTemplateColumns={
              isMobile ? "repeat(2, 1fr)" : "repeat(auto-fill, 300px)"
            }
            justifyContent="space-around"
            rowGap="20px"
            columnGap="1.33%"
          >
            {applyFiltersAndSort.map((item) => (
              <Item item={item} key={`${item.name}-${item.id}`} />
            ))}
          </Box>

          <Box display="flex" justifyContent="center" mt={4}>
            <Pagination
              count={Math.ceil(totalItems / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ShoppingList;
